export class ProfileModel {
  email: any;
  name: any;
  company: any;
  phone: any;
  address: any;
  city: any;
  pincode: any;
  product: any;
  serial: any;
}
