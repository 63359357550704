import { Injectable } from '@angular/core';
import { Plugins, CameraResultType, Capacitor, FilesystemDirectory,
  CameraPhoto, CameraSource } from '@capacitor/core';
  import { Platform } from '@ionic/angular';
const { Camera, Filesystem, Storage } = Plugins;

declare global {
    var path;
}
var image;

@Injectable({
  providedIn: 'root'
})

export class CameraService {
  pic: any;
  photo: any;
  data: any;
  temp : any;
  //constructor() { }

  private platform: Platform;

  constructor(platform: Platform) {
    this.platform = platform;

  }
check()
{
  // console.log(this.data);
}

public async addNewToGallery() {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 80

    }).then(capturedPhoto => {
        this.pic = this.readAsBase64(capturedPhoto);
         //   // console.log('Barcode data', capturedPhoto);
            this.photo = capturedPhoto.webPath;
          // console.log('capturedPhoto', this.photo);
          var xhr = new XMLHttpRequest();
       xhr.open('GET', this.photo);
    xhr.responseType = 'blob';
    xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          //// console.log(reader.result);
            (<any>window).path = reader.result;
         //// console.log(path);
          //      var el = document.getElementById('someId');
        //  el.innerHTML = path;
        }
        reader.readAsDataURL(xhr.response);
    };

    xhr.send();
setTimeout(this.get,2000);
});

  }

get()
{
  this.data=path;

// // console.log(this.data);
}

   private async readAsBase64(cameraPhoto: CameraPhoto) {

    // "hybrid" will detect Cordova or Capacitor
  if (this.platform.is('hybrid')) {
    // Read the file into base64 format
    const file = await Filesystem.readFile({
      path: cameraPhoto.path
    });

    return file.data;
  }
  else {
    // Fetch the photo, read as a blob, then convert to base64 format
    const response = await fetch(cameraPhoto.webPath!);
    const blob = await response.blob();

    return await this.convertBlobToBase64(blob) as string;  }
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });



}
