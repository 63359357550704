import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class duration {
  message : any;

  constructor(public toastController: ToastController,
              public alertController: AlertController) {
  }

      //Function timestamp to duration
      time2dur(unix) {
        var sec = unix/1000;
        var secs3 = Math.floor(sec);

        var min3 = sec/60;
        var mins3 = Math.floor(min3);

        var hour3 = min3/60;
        var hours3 = Math.floor(hour3);

        var day3 = hour3/24;
        var days3 = Math.floor(day3);

        var finsec3 = secs3 - (mins3*60);
        var finsec4 = '' + finsec3;
        if (finsec3<10){ finsec4 = "0" + finsec4};

        var finmin3 = mins3 - (hours3*60);
        var finmin4 = '' + finmin3;
        if (finmin3<10){ finmin4 = "0" + finmin4};

        var finhour3 = hours3 - (days3*24);
        var finhour4 = '' + finhour3;
        if (finhour3<10){ finhour4 = "0" + finhour4};

        return {
          day: days3 + " days", hour: finhour4 + " hours", min: finmin4 + " mins", sec: finsec4 + " secs"
        };

      }

  parseHexString(str) {
    var result = [];
    while (str.length >= 8) {
        result.push(parseInt(str.substring(0, 8), 16));

        str = str.substring(8, str.length);
    }

    return result;
}

createHexString(arr) {
  var result = "";
  var z;

  for (var i = 0; i < arr.length; i++) {
      var str = arr[i].toString(16);

      z = 2 - str.length + 1;
      str = Array(z).join("0") + str;

      result += str;
  }

  return result;
}

  arrayBuffer2str(buf: Uint8Array) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  Str2ArrayBuffer(dataString: string) {
    let data = new Uint8Array(dataString.length);
    for (let i = 0; i < data.length; i++) {
      data[i] = dataString.charCodeAt(i);
    }
    return data;
  }

  ShowToast(mensaje: string) {
    let toast = this.toastController.create({
      message: mensaje,
      duration: 2000
    });
    toast.then(res => res.present());
    // console.log('message'+ mensaje);
    this.message = mensaje;
  }

  ShowAlert(mensaje: string) {
    const alert = this.alertController.create({
      header: 'Error',
      message: mensaje,
      buttons: ['OK']
    });
    alert.then(res => res.present());
  }
}
