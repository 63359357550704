import { Component, ChangeDetectorRef } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FirebaseAuthService } from './firebase-auth.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  public appPages = [
    { title: 'Dashboard', url: './tabs', icon: 'open' },
    { title: 'Profile', url: './profile', icon: 'person' },
    { title: 'My Products', url: './product-list', icon: 'happy' },
    { title: 'Signout', url: '/sign-out', icon: 'power' }
  ];

 exitPages: Array<string> = [
        '/tabs/tab1',
        '/welcome'
    ];
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
     private authService: FirebaseAuthService,
     private changeDetectorRef: ChangeDetectorRef

  ) {
    this.initializeApp();
  }

initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
 });
    }

}
